import React from "react";
import { Link } from "react-scroll";
import './Header.css';

function Header() {

    function toogleMobile() {
        var menuList = document.querySelector('.menu-mobile');

        menuList.style.display = menuList.style.display === "none" ? "block" : "none";

    }
    return (
        <header>
            <nav className="navbar">
                <div className="logo-marca">
                    <img src="img/logoTipo.png" alt="Hass Streaming"></img>
                </div>
                <ul className="menu">
                    <li><Link activeClass="active" to="hero" spy={true} smooth={true} duration={500}>Início</Link></li>
                    <li><Link activeClass="active" to="about" spy={true} smooth={true} duration={500}>Benefícios</Link></li>
                    <li><Link activeClass="active" to="cards" spy={true} smooth={true} duration={500}>Planos</Link></li>
                    <li><Link activeClass="active" to="faq" spy={true} smooth={true} duration={500}>Dúvidas</Link></li>
                </ul>
                <button><h2>Teste Grátis</h2></button>
            </nav>

            <div className='menu-mobile-btn'>
                <h2 onClick={toogleMobile}>Menu</h2>
                <ul className="menu-mobile">
                    <li><Link activeClass="active" to="hero" spy={true} smooth={true} duration={500}>Início</Link></li>
                    <hr/>
                    <li><Link activeClass="active" to="about" spy={true} smooth={true} duration={500}>Benefícios</Link></li>
                    <hr/>
                    <li><Link activeClass="active" to="cards" spy={true} smooth={true} duration={500}>Planos</Link></li>
                    <hr/>
                    <li><Link activeClass="active" to="faq" spy={true} smooth={true} duration={500}>Dúvidas</Link></li>
                </ul>
            </div>
        </header>
    );
}

export default Header;