import React from 'react';
import './Testimonial.css';

const Testimonial = ({ name, description, photo }) => {
    return (
        <div className="testimonial">
            <img data-aos="zoom-in" data-aos-duration="2500" src={photo} alt={name} />
            <h2>{name}</h2>
            <p>{description}</p>
        </div>
    );
};

export default Testimonial;
