import React, { useEffect, useState } from 'react';
import './Faq.css';
import buttonClosed from './faq_arrow.png'; // Importe a imagem do botão personalizado
import AOS from 'aos';
import 'aos/dist/aos.css';
function Faq() {

  useEffect(() => {
    AOS.init();
  });

  const faqData = [
    {
      question: 'O que é?',
      answer: 'Nossa plataforma HAAS STREAMING oferece acesso a uma variedade de canais de televisão, incluindo canais abertos e fechados, para que você possa desfrutar de uma ampla gama de programas e conteúdos.'
    },
    {
      question: 'Como funciona?',
      answer: 'Basta se inscrever em nossa plataforma e utilizar um aplicativo ou dispositivo compatível para transmitir os canais de sua escolha. Tudo o que você precisa é de uma conexão estável com a internet para aproveitar o entretenimento oferecido.'
    },
    {
      question: 'Posso testar antes?',
      answer: 'Sim, oferecemos a opção de teste antes da assinatura. Você pode experimentar nossa plataforma HAAS STREAMING por um período determinado para verificar a qualidade e a variedade de canais disponíveis. Entre em contato conosco para obter mais informações sobre o período de teste.'
    },
    {
      question: 'Como funciona a renovação?',
      answer: 'Ao chegar próximo ao fim do período de assinatura, você receberá um aviso para renovar. A renovação pode ser feita entrando em contato com nossa equipe de suporte. Após a renovação, você continuará imediatamente à ter acesso aos canais e conteúdos sem interrupções.'
    },
    {
      question: 'Quantas telas vou ter disponível?',
      answer: 'Você pode logar em diversos dispositivos, porém não poderá ver em duas telas sem adquirir o ponto extra. Para mais informações, entre em contato.'
    },
    // Adicione mais perguntas e respostas conforme necessário
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const handleButtonClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="Faq-container" id='faq'>
      <h2>AINDA FICOU COM <b>ALGUMA DÚVIDA?</b></h2>
      {faqData.map((item, index) => (
        <div data-aos="flip-up" data-aos-duration="1500" onClick={() => handleButtonClick(index)} className={`Faq-item ${openIndex === index ? 'open' : ''}`} key={index}>
          <div className="Faq-header">
            
          <div className="icon">
            {/* Renderiza o ícone "?" quando o FAQ está fechado */}
            {openIndex !== index && (
              <h2>?</h2>
            )}
            {/* Renderiza o ícone "!" quando o FAQ está aberto */}
            {openIndex === index && (
              <h2>!</h2>
            )}
          </div>

            <h2 className="Faq-title">{item.question}</h2>
            <button className={`Faq-button ${openIndex === index ? 'open' : ''}`}>
              <img src={buttonClosed} alt="Botão Personalizado" />
            </button>
          </div>
          
          {openIndex === index && (
            <div className="Faq-description">
              <p className="Faq-answer">{item.answer}</p>
            </div>
          )}
        </div>
      ))}
    </section>
  );
}

export default Faq;
