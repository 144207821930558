import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Testimonial from './Testimonial';
import './Depoiments.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './About.css';


const testimonialsData = [
    
    {
        id: 1,
        name: 'Isaias',
        description: 'Vibrei com o lançamento do Kung Fu Panda 4 😁',
        photo: './img/depoiments_isah.jpg'
    },
    {
        id: 2,
        name: 'Douglas',
        description: 'atendimento top, agilidade, comprometimento no suporte! muito satisfeito!👏',
        photo: './img/depoiments_douglas.jpg'
    },
    {
        id: 3,
        name: 'Vania Franciele',
        description: 'Atendimento muito bom,suporte eficiente e rápido,super recomendo!',
        photo: './img/depoiments_franciele.jpg'
    },
    {
        id: 4,
        name: 'Benome Elite',
        description: 'Ótimo atendimento, facilidade na comunicação e rapidez quando solicitado assistência técnica. ',
        photo: './img/depoiments_benome.jpg'
    },
    {
        id: 5,
        name: 'Leandro Benegante',
        description: 'Serviços muito bons,atendimento quando necessário, é de imediato que nos atendem, equipe de parabéns.',
        photo: './img/depoiments_leandro.jpg'
    },
    {
        id: 6,
        name: 'Angelo',
        description: 'Pra mim você sempre foi prestativa, eficiente e atenciosa 🙏🏼🙌🏼👏🏼',
        photo: './img/depoiments_douglas.jpg'
    },
    {
        id: 7,
        name: 'Leonardo',
        description: 'Os apps são ótimos difícil mente trava ou tranca sempre q preciso recebo atendimento super indico 😄😄',
        photo: './img/depoiments_leonardo.jpg'
    },
    // Adicione mais depoimentos conforme necessário
];

function Depoiments() {
    useEffect(() => {
        AOS.init();
    });
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        prevArrow: <img data-aos="fade-left" data-aos-duration="3000" src="./img/depoiments_arrow_left.png" alt="Previous" />,
        nextArrow: <img data-aos="fade-right" data-aos-duration="3000" src="./img/depoiments_arrow_right.png" alt="Next" />
    };

    return (
        <section className="depoiments">
            <Slider {...settings}>
                {testimonialsData.map(testimonial => (
                    <Testimonial key={testimonial.id} {...testimonial} />
                ))}
            </Slider>
            <div>
            <img data-aos="fade-in" data-aos-duration="1500" className='bg' src="./img/depoiments-bg.jpg" alt="bg"></img>
            </div>

        </section>
    );
}

export default Depoiments;
