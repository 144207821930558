import './Hero.css';

function Hero() {
    return(
        <section className="hero" id='hero'>
            <img src='img/logoMarca.png' alt='Hass Streaming'></img>
            <div>
                <button className='actived'>ASSINAR</button>
                <button><a href='/revendedores'>REVENDER</a></button>
            </div>
        </section>
    );
};

export default Hero;