import './Main.css';
import Hero from './Hero.js';
import SlideChanel from './SlideChanel.js';
import About from './About.js';
import Cards from './Cards.js';
import Depoiments from './Depoiments.js';
import Faq from './Faq.js';

function Main() {
    const images = [
        '../img/slide/ch_amazon.png',
        '../img/slide/ch_animal.png',
        '../img/slide/ch_cartoon.png',
        '../img/slide/ch_cinemax.png',
        '../img/slide/ch_conmebol.png',
        '../img/slide/ch_disney.png',
        '../img/slide/ch_espn.png',
        '../img/slide/ch_globoplay.png',
        '../img/slide/ch_hbo.png',
        '../img/slide/ch_heh.png',
        '../img/slide/ch_kids.png',
        '../img/slide/ch_megapix.png',
        '../img/slide/ch_mtv.png',
        '../img/slide/ch_multisom.png',
        '../img/slide/ch_nba.png',
        '../img/slide/ch_netflix.png',
        '../img/slide/ch_panamount.png',
        '../img/slide/ch_premiere.png',
        '../img/slide/ch_telecine.png',
        '../img/slide/ch_ufc.png',
        // Adicione mais URLs conforme necessário
      ];

    return(
        <main>
            <Hero/>
            <SlideChanel images={images} />
            <About/>
            <Cards/>
            <Depoiments/>
            <Faq/>
        </main>
    )
};

export default Main;