import './SlideChanel.css';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const SlideChanel = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 6000,
    centerMode: true,
    slidesToShow: 5, // Mostra 5 itens na tela de desktop
    slidesToScroll: 1,
    autoplay: true, // Inicia o autoplay
    autoplaySpeed: -800, // Intervalo de tempo entre os slides (3000 ms = 3 segundos)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Mostra 3 itens na tela de dispositivos móveis
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2, // Pode ajustar para 1 item em dispositivos ainda menores
        }
      }
    ],
    cssEase: 'linear' // Define o efeito de transição como linear
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div className="slide-channel" key={index}>
          <img src={image} alt={`Imagem ${index + 1}`} />
        </div>
      ))}
    </Slider>
  );
};

export default SlideChanel;
